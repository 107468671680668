export const EXCEL_COLUMNS_FOR_FORMATTING = {
  lw_revenue: {
    value: 18,
    isFractional: true,
    isCurruncy: true,
  },
  lw_margin: {
    value: 19,
    isFractional: true,
    isCurruncy: true,
  },
  price: {
    value: 21,
    isFractional: true,
    isCurruncy: true,
  },
  tw_revenue: {
    value: 35,
    isFractional: true,
    isCurruncy: true,
  },
  tw_margin: {
    value: 36,
    isFractional: true,
    isCurruncy: true,
  },
  tw_price: {
    value: 38,
    isFractional: true,
    isCurruncy: true,
  },
  lw_qty: 17,
  tw_qty: 34,
  bulk_remaining: 22,
  oh: 24,
  oo: 25,
  bulk_remaining_intransit:23,
  it: 26,
  ohq: 27,
  wos: {
    value: 28,
    isFractional: true,
  },
  // bulk_remaining_us: 27,
  // oh_us: 28,
  // oo_us: 29,
  // it_us: 30,
  // wos_us: {
  //   value: 31,
  //   isFractional: true,
  // },
  // bulk_remaining_ca: 33,
  // oh_ca: 34,
  // oo_ca: 35,
  // it_ca: 36,
  // wos_ca: {
  //   value: 37,
  //   isFractional: true,
  // },
  stock_out: 30,
  shortfall: 31,
  normal: 32,
  excess: 33,
  lm_qty:39,
  lm_revenue: {
    value: 40,
    isFractional: true,
    isCurruncy: true,
  },
  lm_margin: {
    value: 41,
    isFractional: true,
    isCurruncy: true,
  },
  lm_price: {
    value: 43,
    isFractional: true,
    isCurruncy: true,
  },
  ft_qty:44,
  ft_revenue: {
    value: 45,
    isFractional: true,
    isCurruncy: true,
  },
  ft_margin: {
    value: 46,
    isFractional: true,
    isCurruncy: true,
  },

  ft_price: {
    value: 48,
    isFractional: true,
    isCurruncy: true,
  },
  triw_qty: 49,
  triw_revenue: {
    value: 50,
    isFractional: true,
    isCurruncy: true,
  },
  triw_margin: {
    value: 51,
    isFractional: true,
    isCurruncy: true,
  },
  triw_price: {
    value: 53,
    isFractional: true,
    isCurruncy: true,
  },
};

export const LOST_SALES_EXCEL_COLUMNS_FORMATTING = {
  lost_sales: {
    value: 14,
    isFractional: true,
    isCurruncy: true,
  },
};

export const ALLOCATION_DEEP_DIVE_EXCEL_COLUMNS_FORMATTING = {
  sku_depth_store: {
    value: 21,
    isFractional: true,
    isCurruncy: false,
  },
  grade_revenue: {
    value: 23,
    isFractional: true,
    isCurruncy: true,
  },
  revenue: {
    value: 24,
    isFractional: true,
    isCurruncy: true,
  },
  grade_margin: {
    value: 25,
    isFractional: true,
    isCurruncy: true,
  },
  margin: {
    value: 26,
    isFractional: true,
    isCurruncy: true,
  },
};

export const DAILY_SUMMARY_EXCEL_COLUMNS_FORMATTING = {
  units_allocated: 19,
  dc_available: 20,
  oh: 21,
  oo: 22,
  it: 23

};

export const EXCESS_INV_COLUMNS_FORMATTING = {
  inv: 13,
  unit_sold: 15,
  excess_inv: 16,
  excess_inv_cost : {
    value: 19,
    isFractional: true,
    isCurruncy: true,
  },
}

export const DEFAULT_CONSTRAINTS_COLUMNS_FORMATTING = {
  min_stock: 7,
  max_stock: 8,
  wos: 9
}